/* eslint-disable react/jsx-filename-extension */
import React from "react";
import { graphql } from "gatsby";
import PageTemplate from "@pageTemplates/EventsPage";
// eslint-disable-next-line no-unused-vars
import ContentBuilderFragment from "@fragments/ContentBuilderFragment";
import resolveEvent from "@dataResolvers/resolveEvent";
import QueryContainer, { HeadTemplate } from "../QueryContainer";

export const query = graphql`
  query EventQuery($uri: [String]) {
    craft {
      entry(uri: $uri) {
        ... on Craft_events_default_Entry {
          heroHeading: heading0
          heroDescriptor: descriptor0
          heroBackgroundImage: image0 {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
          startDateTime
          endDateTime
          # Content Builder
          contentBuilder {
            ...ContentBuilderFragment
          }
          formHeading: heading1
          formDescriptor: descriptor1
          formId: heading2
          # Meta
          slug
          url
          title
          metaTitle
          metaDescription
          metaImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
        }
      }
    }
  }
`;

const dataResolver = ({ entry }) => resolveEvent(entry);

export const Head = ({ data, ...rest }) => {
  const { craft } = data;
  const resolvedData = dataResolver(craft);
  return <HeadTemplate {...rest} data={resolvedData} />;
};

const Page = props => (
  <QueryContainer
    template={PageTemplate}
    dataResolver={dataResolver}
    queryName="DefaultQuery"
    {...props}
  />
);

export default Page;
